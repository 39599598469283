import { colors } from 'utils/constants';
import { extendClassName } from 'utils/common';
import { EntryMethodDetails } from 'components/enter/EntryMethod';

export const PageTitleColourScheme = {
  PremiumSubscription: {
    BACKGROUND_COLOUR: EntryMethodDetails.PremiumSubscription.sectionBackground,
    OUTLINE_TEXT_FILL_COLOUR: '#C3FBF1',
  },
  LiteSubscription: {
    BACKGROUND_COLOUR: EntryMethodDetails.LiteSubscription.sectionBackground,
    OUTLINE_TEXT_FILL_COLOUR: '#FCD0A9',
  },
  FreeSubscription: {
    BACKGROUND_COLOUR: EntryMethodDetails.FreeSubscription.sectionBackground,
    OUTLINE_TEXT_FILL_COLOUR: '#FFD4F8',
  },
};
type ColourSchemeType =
  (typeof PageTitleColourScheme)[keyof typeof PageTitleColourScheme];

interface IProps {
  heroBackgroundImage?: string;
  colourScheme?: ColourSchemeType;
  Tag?: keyof JSX.IntrinsicElements;
  tagClassName?: string;
  children: any;
  subheadingComponent?: any;
  style?: object;
}

const PageTitle = ({
  heroBackgroundImage,
  colourScheme = PageTitleColourScheme.PremiumSubscription,
  Tag = 'h1',
  tagClassName,
  children,
  subheadingComponent,
  style,
}: IProps) => (
  <div className="pageTitle" style={style}>
    <div className="heroBackgroundImageContainer">
      {heroBackgroundImage && (
        <img src={heroBackgroundImage} className="heroBackgroundImage" />
      )}
    </div>

    <Tag className={extendClassName('children', tagClassName)}>{children}</Tag>

    {subheadingComponent}

    <style jsx>
      {`
        .pageTitle {
          background: ${colourScheme.BACKGROUND_COLOUR};
          width: 100%;
          min-height: 135px;
          padding: 1em 1.5em;
          position: relative;
          isolation: isolate;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }

        :global(.pageTitle h1) {
          font-weight: 900;
          font-size: 2.2em;
          text-shadow: 2px 2px white;
          letter-spacing: 0;
          margin-top: 0;
        }

        :global(.pageTitle h1 strong) {
          -webkit-text-fill-color: ${colourScheme.OUTLINE_TEXT_FILL_COLOUR};
          -webkit-text-stroke-width: 2.4px;
          -webkit-text-stroke-color: #4a4a4a;
          text-shadow: 3px 3px white;
        }

        :global(.pageTitle h1 em) {
          font-size: 0.7em;
          font-weight: 700;
          text-transform: none;
        }

        .heroBackgroundImageContainer {
          width: 100%;
          max-width: 1000px;
          margin: auto;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: flex-start;
          align-items: flex-end;
          z-index: -1;
        }

        .heroBackgroundImage {
          position: relative;
          height: 100%;
          z-index: -1;
        }

        :global(.pageTitle .roundedButton) {
          background: transparent;
          border: 2px solid ${colors.primaryBrandGray};
          color: ${colors.primaryBrandGray};
          padding: 0.5em 2.5em;
        }

        @media (min-width: 370px) {
          :global(.pageTitle h1) {
            font-size: 2.3em;
          }
        }

        @media (min-width: 500px) {
          .heroBackgroundImage {
            position: absolute;
            top: 0;
            height: 100%;
            right: calc(50% + 80px);
            z-index: -1;
          }
        }

        @media (min-width: 1000px) {
          .heroBackgroundImageContainer {
            left: calc((100% - 1000px) / 2);
          }

          :global(.pageTitle h1) {
            font-size: 2.5em;
          }
        }
      `}
    </style>
  </div>
);

export default PageTitle;
