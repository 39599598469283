import { checkSubscriberStatus } from 'api/auth';
import LoginForm from 'components/authentication/LoginForm';
import MainLayout from 'components/layout/MainLayout';
import PageTitle from 'components/PageTitle';
import { useUIStore } from 'hooks/stores/useUIStore';
import { useUserStore } from 'hooks/stores/useUserStore';
import { observer } from 'mobx-react-lite';
import { NextSeo } from 'next-seo';
import Head from 'next/head';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

const ALLOWED_REDIRECT_URLS = ['enter/payment'];

const Login = observer(() => {
  const { displayToast, navigateToDashboard } = useUIStore();
  const router = useRouter();
  const { currentUser, updateCurrentUser } = useUserStore();
  const isRedirecting = useRef<boolean>(false);

  let redirectUrl =
    typeof router.query.redirect == 'string'
      ? router.query.redirect
      : router.query.redirect?.[0];

  useEffect(() => {
    if (currentUser && !isRedirecting.current ) {
      isRedirecting.current = true;
      console.log('redirecting now to ', redirectUrl);
      if (redirectUrl && !ALLOWED_REDIRECT_URLS.includes(redirectUrl)) {
        router.replace(redirectUrl);
      } else {
        navigateToDashboard()
      }
    }
  }, [currentUser, isRedirecting.current]);

  useEffect(() => {
    if (redirectUrl && redirectUrl !== '/') {
      displayToast({
        title: 'You need to login to view that page',
        color: '#FF6600',
        timeout: 5000,
      });
    }
  }, [redirectUrl]);

  return (
    <MainLayout additionalClassName="login">
      <Head>
        <meta
          name="robots"
          content="noindex"
        />
      </Head>
      <NextSeo
        title="Sign In | Win Epic Prizes | DAYMADE"
        description="Sign in to your DAYMADE account here"
        openGraph={{
          title: 'Sign In | Win Epic Prizes | DAYMADE',
          description: 'Sign in to your DAYMADE account here',
        }}
      />

      <PageTitle>
        Sign <strong>in</strong>
      </PageTitle>

      <div className="loginContent fullWidth flexCentered">
        <p style={{ margin: '1em 0 0' }}>
          Don't have an account yet or signed up with a guest account?
        </p>

        <Link
          href={redirectUrl ? `/register?redirect=${redirectUrl}` : '/register'}
        >
          <a style={{ textDecoration: 'underline' }}>Create an account</a>
        </Link>

        <div className="paddedMaxWidthContainer">
          <LoginForm
            submitButtonTitle={
              redirectUrl === 'checkout' ? 'Continue to payment' : undefined
            }
            completionHandler={async (token) => {
              if (!isRedirecting.current) {
                if (redirectUrl === null || typeof redirectUrl !== 'string') {
                  const { subscriberStatus } = await checkSubscriberStatus(
                    token
                  );

                  // If no redirect path specified, redirect subscribers to home page and non-subscribers to enter page.
                  redirectUrl = subscriberStatus ? '?welcome' : 'enter';
                }
                isRedirecting.current = true;
                Router.replace(`/${redirectUrl}`);
              }
              updateCurrentUser()
            }}
          />
        </div>
      </div>

      <style jsx>
        {`
          .loginContent {
            padding: 4em 0 3em;
          }

          :global(.login .subheading) {
            margin-bottom: 0.5em;
          }
        `}
      </style>
    </MainLayout>
  );
});

export default Login;
